import React from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as yup from 'yup'
import PropTypes from 'prop-types'

import { Button } from '../../styles/generics'
import { SelectField } from '../../styles/forms'

const initialValues = { specialty: '', professional: '' }
const validations = yup.object().shape({
  specialty: yup.number().required('Campo requerido'),
  professional: yup.number().required('Campo requerido')
})

const capitalizeFLetter = (text) => { 
  return text.toLowerCase().split(' ').map(word => 
   word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
} 

const FormSelectProfessional = props => (
  <Container>
    <Formik
      initialValues={initialValues}
      validationSchema={validations}
      onSubmit={props.onSubmit}
      render={renderProps => (
        <Form onSubmit={renderProps.handleSubmit}>
          <FormInputs>
            <SelectField
              name="specialty"
              id="specialty"
              placeholder="Especialidad"
              options={props.specialties.map(s => ({ id: s.idEspecialidad, name: s.nombre }))}
              warning={renderProps.errors.specialty && renderProps.touched.specialty}
              onChange={e => {
                renderProps.handleChange(e)
                renderProps.setFieldValue('professional', '')
                props.onSpecialtyChange(e.currentTarget.value)
              }}
              white
            />
            <SelectField
              name="professional"
              id="professional"
              placeholder="Profesional"
              options={props.professionals.map(p => ({
                id: p.idProfesional,
                name: capitalizeFLetter(p.nombreProfesional)
              }))}
              warning={renderProps.errors.professional && renderProps.touched.professional}
              disabled={props.professionals.length <= 0}
              white
            />
          </FormInputs>
          <ButtonsContainer>
            <SecondButton onClick={props.onCancel}>Cancelar</SecondButton>
            <Button type="submit">Siguiente</Button>
          </ButtonsContainer>
        </Form>
      )}
    />
  </Container>
)

FormSelectProfessional.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  specialties: PropTypes.arrayOf(PropTypes.object).isRequired,
  professionals: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default FormSelectProfessional

/**
 * Styles
 */
const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    margin-top: 30px;
  }
`
const Form = styled.form`
  width: 100%;
`
const FormInputs = styled.div`
  width: 520px;
  margin: auto;
  & > div:not(:first-child) {
    margin-top: 40px;
  }
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
    & > div:not(:first-child) {
      margin-top: 14px;
    }
  }
`
const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 39px;
  margin-top: 60px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    grid-template-columns: 1fr;
    grid-gap: 12px;
    margin-top: 30px;
  }
`
const SecondButton = styled(Button)`
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    grid-row: 2;
  }
`
