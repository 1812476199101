import React from 'react'
import styled, { css } from 'styled-components'
import { ErrorMessage } from 'formik'
import { Input, ErrorField } from '../styles/forms'
import PropTypes from 'prop-types'

/*
  Custom autocomplete created to integrate seamlessly 
  with Formik and existing styled components
*/
export class AutocompleteText extends React.Component{
  constructor (props) {
    super(props);
    this.state = {
      activeSuggestion: 0,
      suggestions: [],
      userInput: '',
    };

    AutocompleteText.propTypes = {
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      tabIndex: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        })
      ).isRequired,
      assignFieldValue: PropTypes.func.isRequired,
    }
  }

  /*
    Method called when the user types
  */
  onTextChanged = (e) => {
    const { options } = this.props;
    const value = e.target.value;
    let suggestions = [];

    if (value.length > 0) {
      const regex = new RegExp(`\\b${value}`, 'i');
      suggestions = options
        .filter(o => regex.test(o.name))
        .slice(0, 10);
    }

    this.setState(() => ({
      suggestions, 
      userInput: value,
      activeSuggestion: 0,
    }));
  }

  /*
    Method called when the user selects a value
    This can be done either by clicking on a suggestion or 
    by hitting "ENTER KEY" or focusing out while matching a suggestion
  */
 suggestionSelected (item) {
    this.setState(() => ({
      activeSuggestion: 0,
      userInput: item.name, 
      suggestions: [],
      selected: item
    }), () => {
      this.props.assignFieldValue(item.id);
    })
  }

  /* 
    Handle method for onKeyDown event
    Currently used for capturing "ENTER KEY"

    Future Improvements:
    Add handling for UP/DOWN keyboard navigation
    (with hover/highlighting support)
  */
  handleKeyDown = (e) => {
    const { activeSuggestion, suggestions } = this.state;

    // User pressed "ENTER KEY"
    if (e.keyCode === 13 && suggestions[activeSuggestion]) {
      e.preventDefault();
      this.suggestionSelected(suggestions[activeSuggestion]);
    }
    
    // User pressed "UP KEY"
    // else if (e.keyCode === 38) {
    //   if (activeSuggestion === 0) {
    //     return;
    //   }

    //   this.setState({ activeSuggestion: activeSuggestion - 1 });
    // }

    // User pressed "DOWN KEY"
    // else if (e.keyCode === 40) {
    //   if (activeSuggestion === suggestions.length -1) {
    //     return;
    //   }

    //   this.setState({ activeSuggestion: activeSuggestion + 1 });
    // }
  }

  /*
    Handle method for onBlur event
  */
  handleBlur = (e) => {
    var currentTarget = e.currentTarget;
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        const { selected, userInput } = this.state;
        const { options } = this.props;

        // chequeo posible match
        const match = options.find(o => o.name.toUpperCase() === userInput.toUpperCase());

        // si tengo match y ese match no coincide con la seleccion previa, asigno el valor
        if (match && (!selected || selected.id !== match.id)) {
          this.suggestionSelected(match);
          return;
        }

        this.setState({ suggestions: [] }, () => {
          if (selected) {
            this.setState({ userInput: selected.name });
          }      
        })      
      }
    }, 1);
  }

  /*
    Method for rendering the suggestion box
  */
  renderSuggestions () {
    const { suggestions } = this.state;
    var {id, name, placeholder, options, assignFieldValue, hasDependentField, ...styleProps} = this.props;

    if (suggestions.length === 0) {
      return null
    }

    return (
      <Suggestions {...styleProps}>
        {suggestions.map((item, i) => 
          <li key={i} onClick={() => this.suggestionSelected(item)} >
            {item.name}
          </li> 
        )}
      </Suggestions>
    )
  }

  render () {
    const { userInput } = this.state
    var {options, assignFieldValue, ...inputProps} = this.props;
    return (
      <Container onBlur={this.handleBlur} > 
        <Input 
          type='text' 
          value={userInput} 
          onChange={this.onTextChanged}
          onKeyDown={this.handleKeyDown}
          {...inputProps}
        />
        {this.renderSuggestions()}
        <ErrorMessage name={inputProps.name} component={ErrorField} />
      </Container>
    )
  }
}

/*
 * Styles
 */
const Container = styled.div`
  position: relative;
`

const Suggestions = styled(({ warning, lightGrey, ...props }) => <ul {...props} />)`
  position: absolute
  z-index: 1 
  width: 100%;
  list-style-type: none;
  text-align: left;
  padding: 0;
  margin: 0;
  border-radius: 0;
  appearance: none;
  border: 1px solid ${props => props.theme.colors.lightBlue};

  color: ${props => (props.warning ? props.theme.colors.redTwo : props.theme.colors.warmGreyTwo)};

  ${props =>
    props.lightGrey &&
    css`
      background-color: ${props => props.theme.colors.cloudyBlueThree};
    `};

  > li {
    padding: 2px 30px 2px 15px;
    cursor: pointer;

    @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
      padding: 10px 30px 10px 15px;
    }

    &:hover {
      color: white;
      background: ${props => props.theme.colors.lightBlue};
    }
  }
`