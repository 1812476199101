import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import * as yup from 'yup'
import DatePicker from 'react-date-picker'
import { Button, DatePickerCustom } from '../../styles/generics'
import {InputField, SelectField, ErrorField } from '../../styles/forms'

const validationSchema = yup.object().shape({
  name: yup.string().required('Campo requerido'),
  lastName: yup.string().required('Campo requerido'),
  email: yup
    .string()
    .email('email no válido')
    .required('Campo requerido'),
  repeatEmail: yup
    .string()
    .email('Email no válido')
    .oneOf([yup.ref('email'), null], 'Los emails deben coincidir')
    .required('Campo requerido'),
  gender: yup.string().required('Campo requerido'),
  birthDate: yup
    .date()
    .required('Campo requerido'),
  credentialNumber: yup.string().required('Campo requerido'),
})

const FormPatientSignUp = props => {
  const initialValues = {
    name: '',
    lastName: '',
    email: props.auth.mail,
    repeatEmail: props.auth.mail,
    gender: '',
    birthDate: '',
    credentialNumber: '',
  }

  return (
    <FormContainer>
      <Formik
        onSubmit={props.onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        render={renderProps => (
          <Form onSubmit={renderProps.handleSubmit}>
            <div>
              <InputField
                type="text"
                name="name"
                value={renderProps.values.name}
                placeholder="Nombre"
                white={1}
                border={1}
                warning={renderProps.errors.name && renderProps.touched.name ? 1 : 0}
                withBorder
              />

            </div>
            <div>
              <InputField
                type="text"
                name="lastName"
                value={renderProps.values.lastName}
                placeholder="Apellido"
                white={1}
                border={1}
                warning={renderProps.errors.lastName && renderProps.touched.lastName ? 1 : 0}
                withBorder
              />
            </div>
            <div>
              <InputField
                type="email"
                name="email"
                value={renderProps.values.email}
                placeholder="Email"
                white={1}
                border={1}
                warning={renderProps.errors.email && renderProps.touched.email ? 1 : 0}
                withBorder
              />
            </div>
            <div>
              <InputField
                type="email"
                name="repeatEmail"
                value={renderProps.values.repeatEmail}
                placeholder="Reconfirmación de email"
                white={1}
                border={1}
                warning={renderProps.errors.repeatEmail && renderProps.touched.repeatEmail ? 1 : 0}
                withBorder
              />
            </div>
            <div>
              <SelectField
                component="select"
                name="gender"
                placeholder="Sexo"
                options={props.genders}
                warning={renderProps.errors.gender && renderProps.touched.gender ? 1 : 0}
                white
              />
            </div>
            <div>
              <DatePickerCustom
                component={DatePicker}
                name="birthDate"
                value={renderProps.values.birthDate}
                onChange={date => renderProps.setFieldValue('birthDate', date)}
                white={1}
                warning={renderProps.errors.birthDate && renderProps.touched.birthDate ? 1 : 0}              
              />
              {renderProps.touched.birthDate &&
                renderProps.errors.birthDate && (
                  <ErrorField>{renderProps.errors.birthDate}</ErrorField>
                )}
            </div>
            <CenterDiv>
              <InputField
                type="text"
                name="credentialNumber"
                value={renderProps.values.credentialNumber}
                placeholder="Número de credencial"
                white={1}
                border={1}
                warning={
                  renderProps.errors.credentialNumber && renderProps.touched.credentialNumber
                    ? 1
                    : 0
                }
                withBorder
              />
            </CenterDiv>
            <Button type="submit">Aceptar</Button>
          </Form>
        )}
      />
    </FormContainer>
  )
}

function mapStateToProps({ auth }) {
  return { auth }
}
export default connect(
  mapStateToProps,
  null
)(FormPatientSignUp)

/**
 * Styles
 */
const FormContainer = styled.div``
const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px 60px;
  margin: 0 auto;
  > button {
    grid-column: span 2;
    width: 320px;
    margin: auto;
  }
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    grid-template-columns: 1fr;
    max-width: 320px;
    > button {
      grid-column: auto;
      width: 100%;
    }
  }
`
const CenterDiv = styled.div`
  grid-column: span 2;
  width: 330px;
  margin: auto;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    grid-column: auto;
    width: 100%;
  }
`
