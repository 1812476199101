export const CLINICS_LIST = [
  { name: 'Clínica Bazterrica', url: 'http://www.bazterrica.com.ar/bazterrica/' },
  { name: 'Clínica Santa Isabel', url: 'http://www.santaisabel.com.ar/santaisabel/' },
  { name: 'Clínica Del Sol', url: 'http://www.cdelsol.com.ar/dnn_clsol/' }
]

export const CLINICS_ID = {
  SantaIsabel: 1,
  Bazterrica: 2,
  DelSol: 3,
  CentroBazte: 4,
  CentroRita: 5
}

export const WEEK_DAYS = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado']
export const WEEK_DAYS_SHORT = ['D', 'L', 'M', 'M', 'J', 'V', 'S']
export const APPOINTMENT_DATE_FORMAT = 'DD/MM/YYYY'

//Audit Actions
export const AUDIT_ACTION_LOGIN = 'Login'

//Appointment Stage
export const APPOINTMENT_STAGE_NONE = 0
export const APPOINTMENT_STAGE_PATIENT = 1
export const APPOINTMENT_STAGE_LIST = 2
export const APPOINTMENT_STAGE_NEW = 3
export const APPOINTMENT_STAGE_CONFIRMED = 4
export const APPOINTMENT_STAGE_CANCELLED = 5
