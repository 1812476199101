import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import SANTA_ISABEL from '../images/santa-isabel.svg'
import BAZTERRICA from '../images/bazterrica.svg'
import DEL_SOL from '../images/del-sol.svg'
import CENTRO_BAZTERRICA from '../images/centro-bazterrica.svg'
import SANTA_RITA from '../images/santa-rita.svg'

const Navigation = () => {
  /* const isUserLoggedIn = Object.keys(props.auth).length */

  return (
    <Container>
      <SmallScreen>
        <MobileRootLink to="/">Turnos en Consultorio</MobileRootLink>
      </SmallScreen>
      <RootLink to="/">
        <Img src={SANTA_ISABEL} alt="Clínica Santa Isabel" />
        <Img src={DEL_SOL} alt="Clínica del Sol" />
        <Img src={BAZTERRICA} alt="Clínica Bazterrica" />
        <Img src={CENTRO_BAZTERRICA} alt="Centro Médico Bazterrica" />
        <Img src={SANTA_RITA} alt="Centro Médico Santa Rita" />
      </RootLink>
    </Container>
  )
}

export default Navigation

/*
 * Styles
 */
const SmallScreen = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const MobileRootLink = styled(Link)`
  color: white;
  font-size: 26px;
  text-decoration: none;
  text-align: center;
`

const RootLink = styled(Link)`
  display: inline-flex;
  height: 100%;
  margin: 0 auto;
`

const Img = styled.img`
  width: 55px;
  heigth: 55px;
  margin-right: 30px;
  margin-bottom: 0px;
`

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 6px;
`

const Container = styled.div`
  width: ${props => props.theme.sizes.breakPoints.small};
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: auto;
    padding: 0 10px;

    ${RootLink}, ${ActionContainer} {
      display: none;
    }

    ${SmallScreen} {
      display: flex;
    }
  }
`
