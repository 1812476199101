const devLocal = {
  endpoints: {
    API_URL: 'http://turnera.miportalclinicas.com.ar:444/api'
  },
  showDevTools: true
}

const remoteDev = {
  endpoints: {
    API_URL: 'http://172.16.4.161:6081/api'
  },
  showDevTools: true
}

const remoteTest = {
  endpoints: {
    API_URL: 'http://172.16.4.161:6081/api'
  },
  showDevTools: true
}

const remotePreProd = {
  endpoints: {
    //API_URL: 'http://172.16.5.41:8081/api',
  },
  showDevTools: true
}

const remoteProd = {
  endpoints: {
    API_URL: 'http://turnera.miportalclinicas.com.ar:444/api'
  },
  showDevTools: false
}

const config =
  process.env.REACT_APP_STAGE === 'prod'
    ? remoteProd
    : process.env.REACT_APP_STAGE === 'preprod'
    ? remotePreProd
    : process.env.REACT_APP_STAGE === 'remotetest'
    ? remoteTest
    : process.env.REACT_APP_STAGE === 'remotedev'
    ? remoteDev
    : devLocal

export default {
  ...config
}
