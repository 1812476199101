import React from 'react'
import { connect } from 'react-redux'
import to from 'await-catch'
import { Switch } from 'react-router-dom'
import styled, { css } from 'styled-components'

import Layout from './Layout'
import { getClinics, getPatientByClinic } from '../api'
import ClinicsTabs from './ClinicsTabs'
import { APPOINTMENT_STAGE_LIST, APPOINTMENT_STAGE_CONFIRMED } from '../constants'
import { setActiveClinic, setPatientDetails, clearAppointmentState } from '../actions'
import ConfirmPatientData from './ConfirmPatientData'
import AppointmentFlow from './AppointmentFlow'
import AppointmentConfirmed from './AppointmentConfirmed'
import AppointmentCancelled from './AppointmentCancelled'
import PatientSignUp from './PatientSignUp'
import PrivateRoute from './PrivateRoute'


class Appointments extends React.Component {
  state = { listOfClinics: [] }

  async componentDidMount() {
    this.props.clearAppointmentState()

    if (!this.props.appointment.activeClinic) {
      this.props.history.replace(`${this.props.match.path}`)
    }

    const [err, { data: clinicsData } = {}] = await to(getClinics())
    if (!err) {
      this.setState({ listOfClinics: clinicsData })
      await this.loadPatientData()
    }
  }

  // receives true when called after alta-paciente for those
  // cases when MW takes way too long to expose newly created user
  async loadPatientData(alreadyCreated = false) {
    const params = {
      clinica: 0,
      tipoDoc: this.props.auth.tipoDocumento.id,
      nroDoc: this.props.auth.nroDocumento
    }

    const [err, { data: patientData } = {}] = await to(getPatientByClinic(params))
    if (!err) {
      this.props.setPatientDetails({
        ...patientData,
        numeroDocumento: this.props.auth.nroDocumento,
        nuevo: alreadyCreated
      })
      if (!patientData && !alreadyCreated) {
        this.props.history.push(`${this.props.match.path}/alta-paciente`)
      } else if (patientData && !alreadyCreated) {
        this.props.history.push(`${this.props.match.path}/datos-paciente`)
      } else if (patientData && alreadyCreated) {
        this.props.history.push(`${this.props.match.path}/nuevo`)
      }
    }
  }

  async componentDidUpdate(prevProps) {
    const currentPath = this.props.location.pathname
    const currentMatch = this.props.match.path
    const prevPath = prevProps.location.pathname

    if (currentPath === currentMatch && prevPath === `${currentMatch}/datos-paciente`) {
      this.props.clearAppointmentState()
    }

    if (currentPath === currentMatch && prevPath === `${currentMatch}/alta-paciente`) {
      await this.loadPatientData(true)
    }
  }

  onClickTab = async activeTab => {
    this.props.setActiveClinic(
      this.state.listOfClinics.find(clinic => clinic.codigoEmpresa === activeTab)
    )
  }

  onClickBack = () => {
    this.props.clearAppointmentState()
    this.props.history.push(`${this.props.match.path}`)
  }

  render() {
    const title =
      this.props.appointment.appointmentStage < APPOINTMENT_STAGE_LIST ? 'Turnos Programados' : ''

    const subtitle =
      this.props.appointment.appointmentStage < APPOINTMENT_STAGE_LIST
        ? 'Pedí un turno presencial o programá una videoconsulta.'
        : ''

    return (
      <Layout title={title} subTitle={subtitle}>
        {this.props.appointment.appointmentStage < APPOINTMENT_STAGE_CONFIRMED && (
          <ClinicsTabs
            listTabs={this.state.listOfClinics}
            onClickTab={this.onClickTab}
            activeTab={
              this.props.appointment.activeClinic
                ? this.props.appointment.activeClinic.codigoEmpresa
                : null
            }
            onClickBack={this.onClickBack}
          />
        )}

        {this.props.appointment.activeClinic &&
          this.props.appointment.patientDetails != null &&
          this.props.appointment.patientDetails.nombreApellido && (
            <Switch>
              <PrivateRoute
                exact
                path={`${this.props.match.path}/datos-paciente`}
                component={ConfirmPatientData}
              />
              <PrivateRoute 
                exact path={`${this.props.match.path}/nuevo`} 
                component={AppointmentFlow} 
              />
              <PrivateRoute
                exact
                path={`${this.props.match.path}/confirmacion`}
                component={AppointmentConfirmed}
              />
              <PrivateRoute
                exact
                path={`${this.props.match.path}/cancelado`}
                component={AppointmentCancelled}
              />
            </Switch>
          )}

        {this.props.appointment.patientDetails != null &&
          !this.props.appointment.patientDetails.nombreApellido &&
          this.props.appointment.activeClinic && (
            <Switch>
              <PrivateRoute
                exact
                path={`${this.props.match.path}/alta-paciente`}
                component={PatientSignUp}
              />
            </Switch>
          )}

        {this.props.appointment.patientDetails != null &&
          !this.props.appointment.patientDetails.nombreApellido &&
          this.props.appointment.activeClinic &&
          this.props.appointment.patientDetails.nuevo && (
            <Content>
              <NoData>Por favor, volvé a intentar en unos momentos.</NoData>
            </Content>
          )}
      </Layout>
    )
  }
}

const mapDispatchToProps = { setActiveClinic, setPatientDetails, clearAppointmentState }
function mapStateToProps({ appointment, auth }) {
  return { appointment, auth }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Appointments)

/*
 * Styles
 */
const margin = css`
  margin: 5px 0;
`
const Content = styled.div`
  ${margin} padding: 20px 40px 40px;
  background: ${props => props.theme.colors.greenFour};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    padding: 10px 10px 10px;
  }
`
const NoData = styled.p`
  font-weight: 600;
  text-align: center;
  color: ${props => props.theme.colors.greenThree};
`
