import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import LogIn from './components/LogIn'
import Appointments from './components/Appointments'
import PrivateRoute from './components/PrivateRoute'

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={LogIn} />
      <PrivateRoute path="/inicio" component={Appointments} />
    </Switch>
  </BrowserRouter>
)

export default Routes
