import axios from 'axios'
import config from './config'
import { store } from './store'
import { showErrorMessage, showLoader, hideLoader, logout } from './actions'

axios.defaults.baseURL = config.endpoints.API_URL

// Pendig Request counter
let pendingRequests = 0

// NOTE: 🔥 Loader component
// Now with support for multiple requests
// Calling setLoadingSpinner with TRUE will increment the pendingRequest counter and show the loader
// Calling setLoadingSpinner with FALSE will decrement the counter and hide the loader if the counter got to CERO.
const setLoadingSpinner = isLoading => {
  if (isLoading) {
    pendingRequests++
    store.dispatch(showLoader())
  } else if (pendingRequests > 0) {
    pendingRequests--
    if (pendingRequests <= 0) {
      store.dispatch(hideLoader())
    }
  }
}

axios.interceptors.request.use(
  config => {
    setLoadingSpinner(true)

    config.headers = {
      accept: '*/*'
    }
    return config
  },
  err => err
)

axios.interceptors.response.use(
  res => {
    setLoadingSpinner(false)
    return res
  },
  err => {
    // NOTE: 🔥 Loader component
    setLoadingSpinner(false)
    const {
      request: { status = 0 },
      message = ''
    } = err
    if (status === 401 || message.includes('Network Error')) {
      store.dispatch(logout())
      store.dispatch(showErrorMessage('Algo salió mal. Por favor, intentá más tarde'))
      return Promise.reject(err)
    }
    store.dispatch(
      showErrorMessage(
        typeof err.response.data.message !== 'undefined'
          ? 'Algo salió mal. Por favor, intentá más tarde'
          : err.response.data
      )
    )
    return Promise.reject(err)
  }
)

/*
 * Metadata
 */

export const getDocumentTypes = async () => {
  const url = '/metadatos/ObtenerValores?clave=TipoDocumento'
  return await axios.get(url)
}

export const getGenders = async () => {
  const url = '/metadatos/ObtenerValores?clave=Sexo'
  return await axios.get(url)
}

/*
 * Appointments
 */

export const getClinics = async () => {
  const url = '/turnos/clinicas'
  return await axios.get(url)
}

export const getSpecialties = async data => {
  const url = '/turnos/especialidades'
  return await axios.get(url, { params: data })
}

export const getProfessionals = async data => {
  const url = '/turnos/profesionales'
  return await axios.get(url, { params: data })
}

export const confirmAppointment = async data => {
  const url = '/turnos/confirmar'
  return await axios.post(url, data)
}

export const getAvailableAppointments = async data => {
  const url = '/turnos/disponibles'
  return await axios.get(url, { params: data })
}

export const getCancelReasons = async clinic => {
  const url = `/turnos/motivosBaja?clinica=${clinic}`
  return await axios.get(url)
}

export const cancelAppointment = async (appointment, clinic, reason) => {
  const url = '/turnos/cancelar'
  const params = {
    clinica: clinic,
    nroSolicitud: appointment,
    codigoMotivo: reason
  }
  return await axios.post(url, params)
}

/*
 * Patient
 */

export const getHealthInsurances = async () => {
  const url = '/pacientes/obrasSociales'
  return await axios.get(url)
}

export const getHealthInsurancePlans = async healthInsurance => {
  const url = `/pacientes/planes?obraSocial=${healthInsurance}`
  return await axios.get(url)
}

export const getPatientLogin = async request => {
  const url = '/pacientes/IniciarSesion'
  return await axios.get(url, { params: request })
}

export const getPatientByClinic = async request => {
  const url = '/pacientes/ObtenerPaciente'
  return await axios.get(url, { params: request })
}

export const patientSignUp = async params => {
  const url = '/pacientes/CrearPacienteMindware'
  return await axios.post(url, params)
}

/*
* Auditoria
*/
export const logAuditAction = async data => {
  const url = `/Auditoria/RegistrarAccion`
  return await axios.post(url, data)
}