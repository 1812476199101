import React from 'react'
import styled, { css } from 'styled-components'
import { Field, ErrorMessage } from 'formik'
import { baseComponent } from './generics'

import ARROW_DOWN from '../images/arrow_down.svg'
import ARROW_DOWN_RED from '../images/arrow_down_red.svg'

export const Input = styled(({ warning, grey, lightGrey, withBorder, ...props }) => <Field {...props} />)`
  ${baseComponent};
  border: none;
  ${props =>
    props.withBorder &&
    css`
      border: 1px solid ${props => props.theme.colors.green};
    `};
  ${props =>
    props.warning &&
    css`
      border: 1px solid ${props => props.theme.colors.redTwo};
      color: ${props => props.theme.colors.redTwo};
    `};
  ${props =>
    props.grey &&
    css`
      background: ${props => props.theme.colors.greyThree};
    `};
  ${props =>
    props.lightGrey &&
    css`
      background: ${props => props.theme.colors.cloudyBlueThree};
    `};
`

const Select = styled(({ warning, lightGrey, white, disabled, ...props }) => <Field {...props} />)`
  ${baseComponent};
  padding: 0 30px 0 15px;
  color: ${props => (props.warning ? props.theme.colors.redTwo : props.theme.colors.warmGreyTwo)};
  border: 1px solid
    ${props => (props.warning ? props.theme.colors.redTwo : props.theme.colors.green)};
  border-radius: 0;
  appearance: none;
  background: ${props =>
    props.warning
      ? `url(${ARROW_DOWN_RED}) no-repeat right`
      : `url(${ARROW_DOWN}) no-repeat right`};
  outline: none;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    padding: 0 42px 0 15px;
    background-size: 36px, auto;
  }
  ${props =>
    props.lightGrey &&
    css`
      background-color: ${props => props.theme.colors.cloudyBlueThree};
      border: ${props => (props.warning ? `1px solid ${props.theme.colors.redTwo}` : 'none')};
    `};
  ${props =>
    props.white &&
    css`
      background-color: white;
    `};
  ${props =>
    props.disabled &&
    css`
      background: ${props => props.theme.colors.grey};
      color: ${props => props.theme.colors.greyThree};
      border: 1px solid ${props => props.theme.colors.greyThree};
    `};
`

export const ErrorField = styled.label`
  display: block;
  color: ${props => props.theme.colors.redTwo};
  text-align: left;
  margin: 5px 0 0 5px;
  font-size: 14px;
`

export const InputField = props => (
  <div>
    <Input {...props} />
    <ErrorMessage name={props.name} component={ErrorField} />
  </div>
)

export const SelectField = ({ placeholder, options, ...props }) => (
  <div>
    <Select component="select" {...props}>
      {placeholder && (
        <option value="" disabled hidden>
          {placeholder}
        </option>
      )}
      {options.map((op, idx) => (
        <option value={op.id} key={idx}>
          {op.name ? op.name : op.value}
        </option>
      ))}
    </Select>
    <ErrorMessage name={props.name} component={ErrorField} />
  </div>
)