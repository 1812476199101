import React from 'react'
import styled from 'styled-components'

import { CLINICS_LIST } from '../constants'
import FACEBOOK from '../images/facebook.svg'
import LINKEDIN from '../images/linkedin.svg'
import INSTAGRAM from '../images/instagram.svg'

const Footer = () => (
  <Container>
    <Social>
      <a href="https://es-la.facebook.com/GrupoOMINT/" target="_blank" rel="noopener noreferrer">
        <img src={FACEBOOK} alt="facebook" />
      </a>
      <a
        href="https://www.linkedin.com/company/grupo-omint"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={LINKEDIN} alt="linkedin" />
      </a>
      <a
        href="https://www.instagram.com/grupoomint/?hl=es-la"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={INSTAGRAM} alt="instagram" />
      </a>
    </Social>
    <Clinics>
      <Div>
        {CLINICS_LIST.map((clinic, idx) => (
          <ClinicLink href={clinic.url} key={idx} target="_blank" rel="noopener noreferrer">
            {clinic.name}
          </ClinicLink>
        ))}
      </Div>
    </Clinics>
  </Container>
)

export default Footer

const Container = styled.footer`
  margin-top: 30px;
  background: ${props => props.theme.colors.warmGrey};
`

const Clinics = styled.div`
  margin: 0;
  background: ${props => props.theme.colors.greyFour};
  display: flex;
  justify-content: center;
`
const ClinicLink = styled.a`
  text-decoration: none;
  color: white;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    :not(:first-child) {
      margin-top: 15px;
    }
  }
`

const Div = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  width: ${props => props.theme.sizes.breakPoints.small};
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    flex-direction: column;
    align-items: center;
  }
`
const Social = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;

  > a {
    :nth-child(even) {
      margin: 0 20px;
    }
    > img {
      height: 22px;
    }
  }
`
