/*
 * Auth
 */
export const LOGIN = 'LOGIN'
export const login = payload => ({ type: LOGIN, payload })

export const LOGOUT = 'LOGOUT'
export const logout = () => ({ type: LOGOUT })

/*
 * Loader
 */
export const SHOW_LOADER = 'SHOW_LOADER'
export const showLoader = () => ({ type: SHOW_LOADER })

export const HIDE_LOADER = 'HIDE_LOADER'
export const hideLoader = () => ({ type: HIDE_LOADER })

/*
 * Error Messages
 */
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE'
export const showErrorMessage = payload => ({ type: SHOW_ERROR_MESSAGE, payload })

export const HIDE_ERROR_MESSAGE = 'HIDE_ERROR_MESSAGE'
export const hideErrorMessage = () => ({ type: HIDE_ERROR_MESSAGE })

/*
 * Appointments
 */

export const SET_ACTIVE_CLINIC = 'SET_ACTIVE_CLINIC'
export const setActiveClinic = payload => ({ type: SET_ACTIVE_CLINIC, payload })

export const SET_PATIENT_DETAILS = 'SET_PATIENT_DETAILS'
export const setPatientDetails = payload => ({ type: SET_PATIENT_DETAILS, payload })

export const SET_APPOINTMENT_DETAILS = 'SET_APPOINTMENT_DETAILS'
export const setAppointmentDetails = payload => ({ type: SET_APPOINTMENT_DETAILS, payload })

export const CLEAR_APPOINTMENT_STATE = 'CLEAR_APPOINTMENT_STATE'
export const clearAppointmentState = () => ({ type: CLEAR_APPOINTMENT_STATE })

export const SET_APPOINTMENT_STAGE = 'SET_APPOINTMENT_STAGE'
export const setAppointmentStage = payload => ({ type: SET_APPOINTMENT_STAGE, payload }) 
