import React from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'
import { connect } from 'react-redux'

import AppointmentHeader from './AppointmentHeader'
import { clearAppointmentState, setAppointmentStage } from '../actions'
import { APPOINTMENT_STAGE_CONFIRMED } from '../constants';
import { Button, AddToCalendarButton } from '../styles/generics'
import AppointmentConfirmedPanel from './AppointmentConfirmedPanel'
import ModalMessage from './ModalMessage'

class AppointmentConfirmed extends React.Component {
  state = { modalOpen: false }

  componentDidMount() {
    this.props.setAppointmentStage(APPOINTMENT_STAGE_CONFIRMED);
  }

  calendarEvent = {
    title: `Turno - ${
      this.props.appointment.appointmentDetails
        ? this.props.appointment.appointmentDetails.specialty.nombre
        : ''
    }`,
    description: `Turno de la especialidad: ${
      this.props.appointment.appointmentDetails
        ? this.props.appointment.appointmentDetails.specialty.nombre
        : ''
    } con el profesional: ${
      this.props.appointment.appointmentDetails
        ? this.props.appointment.appointmentDetails.professional.nombreProfesional
        : ''
    }.`,
    location: `${
      this.props.appointment.activeClinic ? this.props.appointment.activeClinic.direccion : ''
    }`,
    startTime: this.props.appointment.appointmentDetails
      ? moment(this.props.appointment.appointmentDetails.schedule)
      : null,
    endTime: this.props.appointment.appointmentDetails
      ? moment(this.props.appointment.appointmentDetails.schedule).add(1, 'hours')
      : null
  }

  handleFinishFlow = () => {
    this.props.clearAppointmentState()
    this.props.history.push('/inicio/datos-paciente')
  }

  handleAppointmentCancelled = () => this.props.history.push('/inicio/cancelado')

  toggleModal = () => this.setState({ modalOpen: !this.state.modalOpen })

  render() {
    return (
      <React.Fragment>
        <Content>
          <AppointmentHeader
            sectionTitle="Reservar Turno"
            fullName={this.props.appointment.patientDetails.nombreApellido}
            healthInsurance={this.props.appointment.patientDetails.coberturaMedica}
            memberNumber={this.props.appointment.patientDetails.nroAfiliado}
          />
          <Container>
            <AppointmentConfirmedPanel
              handleAppointmentCancelled={this.handleAppointmentCancelled}
              clinic={this.props.appointment.activeClinic.codigoEmpresa}
              id={this.props.appointment.appointmentDetails.id}
            />
            <Details>
              <Span>Número de solicitud:</Span>
              <Value>{this.props.appointment.appointmentDetails.id}</Value>
              <Span>Especialidad:</Span>
              <Value>{this.props.appointment.appointmentDetails.specialty.nombre}</Value>
              <Span>Profesional:</Span>
              <Value>
                {this.props.appointment.appointmentDetails.professional.nombreProfesional}
              </Value>
              <Span>Fecha y hora del turno:</Span>
              <Value>
                {moment(this.props.appointment.appointmentDetails.schedule).format(
                  'DD/MM/YYYY - HH:mm'
                )}
              </Value>
              <Span>Nombre del paciente:</Span>
              <Value>{this.props.appointment.patientDetails.nombreApellido}</Value>
              <Span>Cobertura médica:</Span>
              <Value>{this.props.appointment.patientDetails.coberturaMedica}</Value>
              <Span>Plan/Línea/Cartilla:</Span>
              <Value>{this.props.appointment.patientDetails.planLineaCartilla}</Value>
              <Span>Número de Afiliado:</Span>
              <Value>{this.props.appointment.patientDetails.nroAfiliado}</Value>
            </Details>
            <ButtonsContainer>
              <AddToCalendarButton outline green event={this.calendarEvent} />
              <Button2 onClick={this.handleFinishFlow}>Finalizar</Button2>
            </ButtonsContainer>
          </Container>
        </Content>
        {this.state.modalOpen && (
          <ModalMessage
            message="El email se ha enviado con exito"
            button={{ action: this.toggleModal, label: 'Aceptar' }}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = { clearAppointmentState, setAppointmentStage }
function mapStateToProps({ appointment }) {
  return { appointment }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentConfirmed)

/*
 * Styles
 */
const margin = css`
  margin: 5px 0;
`
const Content = styled.div`
  ${margin} padding: 20px 40px 40px;
  background: ${props => props.theme.colors.cloudyBlueThree};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    padding: 10px 20px 20px;
  }
`
const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const Details = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 40px;
  width: 100%;
  font-size: 14px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    font-size: 13px;
    grid-gap: 13px 3px;
  }
`
const Span = styled.span`
  justify-self: end;
  color: ${props => props.theme.colors.warmGreyTwo};
`
const Value = styled.span`
  color: ${props => props.theme.colors.green};
  font-weight: bold;
`
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    flex-direction: column;
  }
`
const Button2 = styled(Button)`
  width: 320px;
  margin-top: 40px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
    margin-top: 20px;
  }
`
