import { combineReducers, createStore, compose } from 'redux'
import persistState from 'redux-sessionstorage'
import config from './config'

import { authReducer, loaderReducer, errorMessageReducer, appointmentReducer } from './reducers'

const rootReducer = combineReducers({
  auth: authReducer,
  loader: loaderReducer,
  errorMessage: errorMessageReducer,
  appointment: appointmentReducer
})

export const store = createStore(
  rootReducer,
  compose(
    persistState('auth', { key: 'auth' }), // Sync auth state with sessionStorage in browser
    window.__REDUX_DEVTOOLS_EXTENSION__ && config.showDevTools
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f
  )
)
