import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import { store } from './store'

import { GlobalStyle } from './styles/globals'

import theme from './styles/theme'
import Routes from './Routes'
import Loader from './components/Loader'
import ApiErrorMessage from './components/ApiErrorMessage'

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalStyle />
        <ApiErrorMessage />
        <Loader />
        <Routes />
      </React.Fragment>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)
