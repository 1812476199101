import React from 'react'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { store } from '../store'

import to from 'await-catch'
import PatientReducedDetails from './PatientReducedDetails'
import FormSignUp from './Forms/FormPatientSignUp'
import { getGenders, patientSignUp, getPatientByClinic } from '../api'
import { setPatientDetails, showLoader, hideLoader } from '../actions'
import { sleepFor } from '../helpers'

class PatientSignUp extends React.Component {
  state = { genders: [] }
  async componentDidMount() {
    this.setState({ genders: [] })

    const [err, { data: genders } = {}] = await to(getGenders())
    if (!err) {
      this.setState({
        genders: genders.map(dt => ({ id: dt.id, name: dt.nombre }))
      })
    }
  }

  handleSubmit = async values => {
    const params = {
      mail: values.email,
      nombres: values.name,
      apellidos: values.lastName,
      tipoDocumento: this.props.auth.tipoDocumento.name,
      documento: this.props.auth.nroDocumento,
      sexo: values.gender,
      dob: values.birthDate,
      nroAfiliado: values.credentialNumber,
      obraSocial: this.props.auth.obraSocial.id,
      plan: this.props.auth.plan.id,
      clinica: this.props.appointment.activeClinic.codigoEmpresa
    }

    const [err] = await to(patientSignUp(params))
    if (!err) {
      const params = {
        clinica: 0,
        tipoDoc: this.props.auth.tipoDocumento.id,
        nroDoc: this.props.auth.nroDocumento
      }

      const [err, { data: patientData } = {}] = await to(getPatientByClinic(params))
      if (!err) {
        if (patientData === null || patientData === undefined) {
          console.log("user fetch creation return no data, waiting for redirect...")
          store.dispatch(showLoader())
          await sleepFor(5000) //mw might be taking its sweet time...
          store.dispatch(hideLoader())
          this.props.history.push('/inicio')
        } else {
          this.props.setPatientDetails({
            ...patientData,
            numeroDocumento: this.props.auth.nroDocumento,
            nuevo: true
          })
          this.props.history.replace('/inicio/nuevo')
        }
      }
    }
  }

  render() {
    return (
      <Content>
        <DetailsBox>
          <PatientReducedDetails patient={this.props.appointment.patientDetails} />
        </DetailsBox>

        <FormSignUp genders={this.state.genders} onSubmit={this.handleSubmit} />
      </Content>
    )
  }
}
const mapDispatchToProps = { setPatientDetails }
function mapStateToProps({ appointment, auth }) {
  return { appointment, auth }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientSignUp)

/**
 * Styles
 */
const margin = css`
  margin: 5px 0;
`
const Content = styled.div`
  ${margin} padding: 30px 40px 40px;
  background: ${props => props.theme.colors.cloudyBlueThree};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    padding: 30px 20px 20px;
  }
`
const DetailsBox = styled.div`
  margin-bottom: 30px;
`
