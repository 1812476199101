import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Button, LinkButton } from '../styles/generics'

const PatientDetails = props => (
  <Container>
    <Details>
      <Span>Nombre y Apellido:</Span>
      <Value>{props.patient.nombreApellido}</Value>
      <Span>Número de Documento:</Span>
      <Value>{props.patient.numeroDocumento}</Value>
      <Span>Cobertura Médica:</Span>
      <Value>{props.patient.coberturaMedica}</Value>
      <Span>Plan/Línea/Cartilla:</Span>
      <Value>{props.patient.planLineaCartilla}</Value>
      <Span>Número de Afiliado:</Span>
      <Value>{props.patient.nroAfiliado}</Value>
      {props.showButtons && (
        <React.Fragment>
          <ConfirmTitle>¿Estos datos son correctos?</ConfirmTitle>
          <LinkButtonWide to="/inicio/nuevo">Confirmar</LinkButtonWide>
          <ButtonWide onClick={props.handleNotCorrect}>Modificar</ButtonWide>
        </React.Fragment>
      )}
    </Details>
  </Container>
)

PatientDetails.propTypes = {
  handleNotCorrect: PropTypes.func.isRequired,
  patient: PropTypes.shape({
    nombreApellido: PropTypes.string,
    coberturaMedica: PropTypes.string,
    planLineaCartilla: PropTypes.string,
    nroAfiliado: PropTypes.string
  }).isRequired
}

export default PatientDetails

/*
 * Styles
 */
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Details = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 40px;
  width: 100%;
  font-size: 14px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    grid-gap: 13px 3px;
    font-size: 13px;
  }
`
const Span = styled.span`
  justify-self: end;
  color: ${props => props.theme.colors.warmGrey};
`
const Value = styled.span`
  color: ${props => props.theme.colors.green};
  font-weight: bold;
`
const ConfirmTitle = styled.h4`
  margin: 29px 0 25px;
  color: ${props => props.theme.colors.green};
  grid-column: span 2;
  text-align: center;
  font-size: 16px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    margin: 16px 0 8px 0;
  }
`
const LinkButtonWide = styled(LinkButton)`
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    grid-column: span 2;
  }
`
const ButtonWide = styled(Button)`
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    grid-column: span 2;
  }
`
