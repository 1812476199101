export default {
  black: '#212121',
  greyTwo: '#333333',
  warmGreyTwo: '#616161',
  greyFour: '#827f7f',
  greyThree: '#9b9b9b',
  warmGrey: '#9e9e9e',
  lightBlue: '#0092ff',
  lightBlueTwo: '#add8e6',
  darkishBlue: '#023979',
  darkishBlueTwo: '#174984',
  cloudyBlueTwo: '#c2cdda',
  cloudyBlueThree: '#ecf0f3',
  white: '#e0e0e0',
  whiteTwo: '#cbcbcb',
  green: '#04705e',
  greenTwo: '#00b496',
  greenThree: '#015445',
  greenFour: '#ecf3ef',
  red: '#e2cccf',
  redTwo: '#700210',
  redThree: '#c0bcbc',
  grey: '#c6cbd4',
  greyFive: '#f6f5f5',
  greySix: '#7f8fa4',

}
