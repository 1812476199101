import React from 'react'
import { connect } from 'react-redux'

const withAuth = WrappedComponent => {
  const WithAuth = props => <WrappedComponent {...props} />
  WithAuth.displayName = `WithAuth(${getDisplayName(WrappedComponent)})`
  return connect(mapStateToProps)(WithAuth)
}

function mapStateToProps({ auth }) {
  return { auth }
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export default withAuth