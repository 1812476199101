import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import Modal from './Modal'
import { MaterialIcon } from '../styles/generics'


const Loader = props =>
  props.loader ? (
    <Modal>
      <Container>
      <MaterialIcon name="access_time" />
        Un momento por favor
      </Container>
    </Modal>
  ) : null

const mapStateToProps = ({ loader }) => {
  return { loader }
}
export default connect(mapStateToProps)(Loader)

/*
 * Styles
 */
const Container = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  padding: 40px;
  color: ${props => props.theme.colors.green};

  @media (min-width: ${props => props.theme.sizes.breakPoints.small}) {
    padding: 70px 120px;
  }

  > i {
    font-size: 60px;
    margin-bottom: 20px;
  }
`
