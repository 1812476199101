import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { hideErrorMessage } from '../actions'
import Modal from './Modal'
import { Button, MaterialIcon } from '../styles/generics'

const ApiErrorMessage = props =>
  props.errorMessage.message ? (
    <Modal>
      <Container>
        <MaterialIcon name="error" />
        {props.errorMessage.message}
        <ActionContainer>
          <Button outline green onClick={props.hideErrorMessage}>
            Cerrar
          </Button>
        </ActionContainer>
      </Container>
    </Modal>
  ) : null

const mapDispatchToProps = { hideErrorMessage }
function mapStateToProps({ errorMessage }) {
  return { errorMessage }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApiErrorMessage)

/*
 * Styles
 */
const Container = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  padding: 20px;
  color: ${props => props.theme.colors.redTwo};
  font-size: 24px;

  @media (min-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: ${props => props.theme.sizes.breakPoints.small};
  }

  > i {
    font-size: 60px;
    margin-bottom: 20px;
  }
`

const ActionContainer = styled.div`
  margin: 30px auto 0 auto;
  width: 50%;

  > button:first-child {
    margin-bottom: 10px;
  }

  @media (min-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: flex;
  }
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
  }
`
