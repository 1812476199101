import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const AppointmentHeader = props => (
  <Container>
    <SectionName>{props.sectionTitle}</SectionName>
    <UserInfo>
      {props.fullName}
      <br />
      <span>
        {props.healthInsurance} {props.memberNumber}
      </span>
    </UserInfo>
  </Container>
)

AppointmentHeader.propTypes = {
  fullName: PropTypes.string.isRequired,
  healthInsurance: PropTypes.string.isRequired,
  memberNumber: PropTypes.string.isRequired
}

export default AppointmentHeader

/*
 * Styles
 */
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.theme.colors.green};
  box-shadow: inset 0 -3px 0 0 ${props => props.theme.colors.green};
  padding-bottom: 7px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    align-items: flex-end;
    box-shadow: inset 0 -1.2px 0 0 ${props => props.theme.colors.green};
  }
`

const SectionName = styled.h4`
  font-size: 32px;
  margin: 0;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    font-size: 18px;
  }
`

const UserInfo = styled.div`
  text-align: right;
  font-weight: bold;

  span {
    font-size: 14px;
    font-weight: normal;
  }
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    font-size: 12px;
    span {
      font-size: 11px;
    }
  }
`
