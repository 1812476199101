import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import to from 'await-catch'
import { Redirect } from 'react-router-dom'

import moment from 'moment'
import { login as loginAction } from '../actions'
import { AUDIT_ACTION_LOGIN } from '../constants'
import FormLogIn from '../components/Forms/FormLogIn'
import BACKGROUND from '../images/background_login.jpg'
import {
  getDocumentTypes,
  getHealthInsurances,
  getHealthInsurancePlans,
  logAuditAction
} from '../api'

class LogIn extends React.Component {
  state = { isLoggedIn: false, plans: [], documentTypes: [], healthInsurances: [] }

  componentWillMount() {
    this.checkLoggedInUser()
  }

  async componentDidMount() {
    const [err, [{ data: documentTypes } = {}, { data: healthInsurances } = {}]] = await to([
      getDocumentTypes(),
      getHealthInsurances()
    ])
    if (!err) {
      this.setState({
        documentTypes: documentTypes.map(dt => ({ id: dt.id, name: dt.nombre })),
        healthInsurances: healthInsurances.map(hi => ({ id: hi.id, name: hi.nombre }))
      })
    }
  }

  checkLoggedInUser = () => {
    if (Object.keys(this.props.auth).length) {
      this.setState({ isLoggedIn: true })

      const request = {
        documento: this.props.auth.nroDocumento,
        email: this.props.auth.mail,
        idObrasocial: this.props.auth.obraSocial.id,
        fechaHora: moment().utc().format('YYYY-MM-DD HH:mm'),
        accion: AUDIT_ACTION_LOGIN
      }

      logAuditAction(request)
    }
  }

  handleSubmit = async values => {
    const request = {
      Documento: values.docNum,
      TipoDocumento: values.docType,
      ObraSocial: values.healthInsurance,
      Plan: values.healthPlan,
      Email: values.email
    }

    // initial fetch removed 
    //const [err, { data } = {}] = await to(getPatientLogin(request))
    //if (!err) {
      this.props.loginAction({
        //...data,
        mail: values.email,
        nroDocumento: values.docNum,
        tipoDocumento: this.state.documentTypes.find(dt => dt.id === request.TipoDocumento),
        obraSocial: this.state.healthInsurances.find(os => os.id === parseInt(request.ObraSocial)),
        plan: this.state.plans.find(hp => hp.id === parseInt(request.Plan))
      })

      this.checkLoggedInUser()
    //}
  }

  handleHealthInsuranceChange = async value => {
    const [err, { data } = {}] = await to(getHealthInsurancePlans(value))
    if (!err) {
      this.setState({ plans: data.map(p => ({ id: p.id, name: p.nombre })) })
    }
  }

  render() {
    if (this.state.isLoggedIn) {
      return <Redirect to="/inicio" />
    }

    return (
      <Container>
        <FormLogIn
          docTypes={this.state.documentTypes}
          plans={this.state.plans}
          healthInsurances={this.state.healthInsurances}
          handleSubmit={this.handleSubmit}
          onHealthInsuranceChange={this.handleHealthInsuranceChange}
        />
        <Img src={BACKGROUND} alt="Fondo LogIn" />
      </Container>
    )
  }
}

const mapDispatchToProps = { loginAction }
function mapStateToProps({ auth }) {
  return { auth }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogIn)

/*
 * Styles
 */
const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Container = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  align-items: center;
  justify-items: end;

  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    max-width: 100%;
    grid-template-columns: 1fr;
    justify-items: center;
    padding: 8px;
    background: url(${BACKGROUND}) no-repeat center center;
    background-size: cover;

    ${Img} {
      display: none;
    }
  }
`
