import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Navigation from './Navigation'
import Footer from './Footer'
import { Paragraph } from '../styles/generics'

const Layout = props => (
  <Container>
    <HeaderContainer>
      <Navigation />
    </HeaderContainer>

    <Main>
      <Titles>
        {props.title && <Title>{props.title}</Title>}
        {props.subTitle && <SubTitle as="div">{props.subTitle}</SubTitle>}
      </Titles>

      {props.children}
    </Main>
    <Footer />
  </Container>
)

Layout.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

Layout.defaultProps = {
  title: '',
  subTitle: ''
}

export default Layout

/*
 * Styles
 */
const HeaderContainer = styled.nav`
  height: ${props => props.theme.sizes.layout.header};
  background: ${props => props.theme.colors.darkishBlue};
  box-shadow: inset 0 -6px 0 0 ${props => props.theme.colors.greenTwo};

  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    height: ${props => props.theme.sizes.layout.smallHeader};
  }
`

const Main = styled.main`
  width: ${props => props.theme.sizes.breakPoints.small};
  margin: 0 auto;
  flex-grow: 1;

  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
    padding: 30px;
  }
`

const Titles = styled.div`
  text-align: center;
  padding: 50px 0;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    padding: 15px 0;
  }
`

const Title = styled.h4`
  color: ${props => props.theme.colors.green};
  font-size: 28px;
  margin: 0;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const UL = styled.ul`
  padding: 0;
  list-style-type: none;
  > li {
    margin: 10px 0;
  }
`
const SubTitle = styled(Paragraph)``
