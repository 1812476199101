import React from 'react'
import { connect } from 'react-redux'
import { setAppointmentStage } from '../actions'

const withAppointment = WrappedComponent => {
  const WithAppointment = props => <WrappedComponent {...props} />
  const mapDispatchToProps = { setAppointmentStage }
  WithAppointment.displayName = `WithAppointment(${getDisplayName(WrappedComponent)})`
  return connect(mapStateToProps, mapDispatchToProps)(WithAppointment)
}

function mapStateToProps({ appointment }) {
  return { appointment }
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export default withAppointment
