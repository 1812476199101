import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const AlertMessage = props =>
  <Container type={props.type} marginTop={props.marginTop}>
    {props.children}
  </Container>

export default AlertMessage

AlertMessage.propTypes = {
  type: PropTypes.oneOf(['error']).isRequired
}

/*
 * Styles
 */
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 75px;
  font-size: 14px;
  font-weight: bold;
  ${props => props.marginTop &&
    css` margin-top:${props.marginTop}px;
  `}
  ${props =>
    props.type === 'error' &&
    css`
      background: ${props.theme.colors.red};
      color: ${props.theme.colors.redTwo};
    `};
  ${props =>
    props.type === 'informative' &&
    css`
      background: ${props.theme.colors.lightBlueTwo};
      color: ${props.theme.colors.darkishBlue};
    `}  
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    padding: 20px;
  }
`