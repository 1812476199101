import React from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import { InputField, SelectField } from '../../styles/forms'
import { AutocompleteText } from '../../form-components/autocomplete'
import { Button } from '../../styles/generics'
import SANTA_ISABEL from '../../images/santa-isabel-login.svg'
import BAZTERRICA from '../../images/bazterrica-login.svg'
import DEL_SOL from '../../images/del-sol-login.svg'
import CENTRO_BAZTERRICA from '../../images/centro-bazterrica-login.svg'
import SANTA_RITA from '../../images/santa-rita-login.svg'

const initialValues = { docNum: '', docType: '', healthInsurance: '', healthPlan: '', email: '' }

const FormLogIn = props => {
  const validationSchema = yup.object().shape({
    docNum: yup.string()
      .matches(/^[0-9]*$/, 'El campo debe ser numérico')
      .test('len', 'El campo debe tener a lo sumo 9 dígitos', val => !val || val.toString().length <= 9)
      .notOneOf(['0'], 'El campo no puede ser 0')
      .required('Campo requerido'),
    docType: yup.string().required('Campo requerido'),
    healthInsurance: yup.string().required('Campo requerido'),
    healthPlan: props.plans.length
      ? yup.string().required('Campo requerido')
      : yup.string().notRequired(),
    email: yup
      .string()
      .email('Email no válido')
      .required('Campo requerido')
  })

  return (
    <Container>
      <H3>Bienvenido a </H3>
      <H1>Turnos para</H1>
      <H1>Consultas Médicas</H1>
      <ClinicLogo src={SANTA_ISABEL} alt="Clínica Santa Isabel" />
      <ClinicLogo src={DEL_SOL} alt="Clínica del Sol" />
      <ClinicLogo src={BAZTERRICA} alt="Clínica Bazterrica" />
      <ClinicLogo src={CENTRO_BAZTERRICA} alt="Centro Médico Bazterrica" />
      <ClinicLogo src={SANTA_RITA} alt="Centro Médico Santa Rita" />

      <FormContainer>
        <Formik
          onSubmit={props.handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={renderProps => (
            <form onSubmit={renderProps.handleSubmit}>
              <SelectField
                name="docType"
                id="docType"
                placeholder="Tipo de Documento"
                tabIndex="0"
                options={props.docTypes}
                warning={renderProps.errors.docType && renderProps.touched.docType}
                lightGrey
              />
              <InputField
                name="docNum"
                id="docNum"
                placeholder="N° Documento"
                tabIndex="0"
                warning={renderProps.errors.docNum && renderProps.touched.docNum}
                lightGrey
              />

              <AutocompleteText 
                name="healthInsurance"
                id="healthInsurance"
                placeholder="Obra Social/Prepaga"
                tabIndex="0"
                options={props.healthInsurances}
                warning={renderProps.errors.healthInsurance && renderProps.touched.healthInsurance}
                lightGrey
                assignFieldValue={(value) => {
                  renderProps.setFieldValue('healthInsurance', value)
                  renderProps.setFieldValue('plan', '')
                  props.onHealthInsuranceChange(value)
                }}
              />

              {props.plans.length > 0 && (
                <SelectField
                  name="healthPlan"
                  id="healthPlan"
                  placeholder="Plan"
                  tabIndex="0"
                  options={props.plans}
                  warning={renderProps.errors.healthPlan && renderProps.touched.healthPlan}
                  lightGrey
                />
              )}
              
              <InputField
                name="email"
                id="email"
                type="email"
                placeholder="Email"
                tabIndex="0"
                warning={renderProps.errors.email && renderProps.touched.email}
                lightGrey
              />
              <Button type="submit">Ingresar</Button>
            </form>
          )}
        />
      </FormContainer>
    </Container>
  )
}

FormLogIn.propTypes = {
  handleSubmit: PropTypes.func.isRequired
}

export default FormLogIn

/*
 * Styles
 */
const Container = styled.div`
  max-width: 400px;
  text-align: center;

  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
    height: 100%
    margin: auto;
    background-color: rgba(255, 255, 255, 0.7)
  }
`

const FormContainer = styled.div`
  max-width: 320px;
  margin: 0 auto;

  div {
    margin-bottom: 15px;
  }
`

const ClinicLogo = styled.img`
  width: 55px;
  heigth: 55px;
  margin: 10px;
`

const H3 = styled.h3`
  color: ${props => props.theme.colors.warmGrey};
  font-size: 24px;
  margin: 0;

  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    color: ${props => props.theme.colors.black};
  }
`

const H1 = styled.h1`
  color: ${props => props.theme.colors.green};
  margin: 0;
  font-size: 40px;
`