import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { Paragraph } from '../styles/generics'

const PatientReducedDetails = props => (
  <Container>
    <Title>Datos del paciente</Title>
    <Details>
      <Span>Número de Documento:</Span>
      <Value>{props.auth.nroDocumento}</Value>
      <Span>Cobertura Médica:</Span>
      <Value>{props.auth.obraSocial.name}</Value>
      <Span>Plan/Línea/Cartilla:</Span>
      <Value>{props.auth.plan.name}</Value>
    </Details>
  </Container>
)

function mapStateToProps({ auth }) {
  return { auth }
}
export default connect(
  mapStateToProps,
  null
)(PatientReducedDetails)

/*
 * Styles
 */
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Title = styled(Paragraph)`
  margin: 30px 0;
  font-weight: 600;
`
const Details = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 40px;
  width: 100%;
  font-size: 14px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    grid-gap: 13px 3px;
    font-size: 13px;
  }
`
const Span = styled.span`
  justify-self: end;
  color: ${props => props.theme.colors.warmGrey};
`
const Value = styled.span`
  color: ${props => props.theme.colors.green};
  font-weight: bold;
`