import React from 'react'
import styled, { css } from 'styled-components'
import { Field } from 'formik'
import { Link } from 'react-router-dom'
import AddToCalendar from 'react-add-to-calendar'

export const baseComponent = css`
  display: block;
  width: 100%;
  font-size: 16px;
  padding: 0 10px;
  height: ${props => props.theme.sizes.forms.height};
  background: white;
  color: ${props => props.theme.colors.greyTwo};
`

const baseButton = css`
  ${baseComponent};
  background: ${props => props.theme.colors.green};
  border: 1px solid ${props => props.theme.colors.green};
  color: white;
  &:hover {
    background: ${props => props.theme.colors.greenThree};
  }
  ${props =>
    props.outline &&
    css`
      border: 1px solid ${props => (props.green ? props.theme.colors.green : `white`)};
      color: ${props => (props.green ? props.theme.colors.green : `white`)};
      background: transparent;
      &:hover {
        background: ${props => props.theme.colors.greenThree};
        color: white;
      }
    `};
  ${props =>
    props.warning &&
    css`
      background: ${props => props.theme.colors.redTwo};
      border-color: ${props => props.theme.colors.redTwo};
      &:hover {
        background: ${props => props.theme.colors.redTwo};
      }
    `};
  ${props =>
    props.disabled &&
    css`
      background: ${props => props.theme.colors.grey};
      border-color: ${props => props.theme.colors.grey};
      &:hover {
        background: ${props => props.theme.colors.grey};
        cursor: not-allowed;
      }
    `};
`

const Input = styled(Field)`
  background: ${props => props.theme.colors.cloudyBlueThree};
  color: ${props => props.theme.colors.warmGreyTwo};
  ${baseComponent} border: none;

  &:focus::placeholder {
    color: transparent;
    font-weight: lighter;
  }

  ${props =>
    props.white &&
    css`
      background: white;
    `};

  ${props =>
    props.border &&
    css`
      border: 1px solid ${props => props.theme.colors.green};
    `};

  ${props =>
    props.warning &&
    css`
      border: 1px solid ${props => props.theme.colors.redTwo};
      color: ${props => props.theme.colors.redTwo};
    `};
`

export const Button = styled.button`
  ${baseButton};
`

export const LinkButton = styled(Link)`
  ${baseButton};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Paragraph = styled.p`
  margin: 0;
  color: ${props => props.theme.colors.warmGreyTwo};
  ${props =>
    props.center &&
    css`
      text-align: center;
    `};
  ${props =>
    props.bold &&
    css`
      font-weight: bold;
    `};
  ${props =>
    props.underline &&
    css`
      text-decoration: underline;
    `};
`

export const MaterialIcon = props => (
  <i className={`material-icons`} {...props}>
    {props.name}
  </i>
)

export const AddToCalendarButton = props => (
  <AddToCalendarButtonWrapper {...props}>
    <AddToCalendar event={props.event} buttonLabel="Agregar a mi calendario" />
  </AddToCalendarButtonWrapper>
)

export const DatePickerCustom = styled(Input)`
  background: white;
  width: 100%;
  height: 40px;
  ${props =>
    props.warning &&
    css`
      border: 0;
    `};
  padding: 0;
  > .react-date-picker__wrapper {
    width: 100%;
    height: 100%;
    border: 1px solid ${props => props.theme.colors.green};
    padding: 0 5px 0 15px;
  }
  > .react-date-picker__button {
    width: 100%;
    border: 1px solid ${props => props.theme.colors.green};
    padding: 0 10px;
    height: 100%;
    ${props =>
      props.warning &&
      css`
        border: 1px solid ${props => props.theme.colors.redTwo};
      `} & .react-date-picker__button__input__input {
      font-size: 16px;
      color: ${props => props.theme.colors.warmGreyTwo};
    }
  }
  & .react-calendar {
    color: ${props => props.theme.colors.greyThree};
    font-size: 12px;
    border: 1px solid ${props => props.theme.colors.green};
    padding: 10px;
    & .react-calendar__navigation__label {
      color: ${props => props.theme.colors.green};
      font-size: 16px;
      font-weight: 600;
    }
    & .react-calendar__navigation__arrow {
      color: ${props => props.theme.colors.green};
      font-size: 36px;
    }
    & .react-calendar__tile--active {
      background: ${props => props.theme.colors.green};
      color: white;
      font-weight: 600;
    }
    & .react-calendar__month-view__days__day--neighboringMonth {
      background: ${props => props.theme.colors.white};
      color: ${props => props.theme.colors.grey};
    }
  }
`

const AddToCalendarButtonWrapper = styled.div`
  .react-add-to-calendar {
    -webkit-font-smoothing: antialiased;
    position: relative;
    display: block;
    width: 320px;
    margin-top: 40px;
    @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
      width: 100%;
      margin-top: 20px;
    }

    &__wrapper {
      zoom: 1;
      cursor: pointer;
    }

    &__button {
      ${baseButton};
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        display: flex;
      }
    }

    &__icon {
      &--right {
        padding-left: 5px;
      }

      &--left {
        padding-right: 5px;
      }
    }

    &__dropdown {
      position: absolute;
      width: 100%;
      padding: 5px 0 5px 8px;
      box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
      border: 1px solid ${props => props.theme.colors.green};
      background-color: #fff;
      text-align: left;

      ul {
        list-style: none;
        margin: 0;
        padding-left: 63px;
        li {
          display: flex;
          height: 25px;
          align-items: center;
          a {
            color: #000;
            text-decoration: none;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;

            i {
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
`
