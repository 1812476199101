import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import ClinicsTabsMessage from './ClinicsTabsMessage'

const ClinicsTabs = props => {
  const clickTab = id => {
    if (props.activeTab !== id) {
      props.onClickTab(id)
    }
  }
  return (
    <Container clinicSelected={props.activeTab}>
      {props.listTabs && (
        <React.Fragment>
          {props.activeTab && props.onClickBack && (
            <IconBack className="material-icons" onClick={props.onClickBack}>
              keyboard_arrow_left
            </IconBack>
          )}
          {props.listTabs.map(
            ({ codigoEmpresa: id, nombre: name, direccion: extra, mensaje: msg }) => (
              <Tab
                onClick={() => clickTab(id)}
                key={id}
                active={id === props.activeTab}
                hide={props.activeTab && id !== props.activeTab}
              >
                {msg && <ClinicsTabsMessage message={msg} />}
                <h4>{name}</h4>
                {/* {extra && <p>({extra})</p>} */}
              </Tab>
            )
          )}
        </React.Fragment>
      )}
    </Container>
  )
}

ClinicsTabs.propTypes = {
  listTabs: PropTypes.arrayOf(
    PropTypes.shape({
      codigoEmpresa: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      nombre: PropTypes.string,
      direccion: PropTypes.string,
      mensaje: PropTypes.string
    })
  ).isRequired,
  onClickTab: PropTypes.func.isRequired,
  activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClickBack: PropTypes.func
}

ClinicsTabs.defaultProps = { activeTab: null }

export default ClinicsTabs

/*
 * Styles
 */
const Container = styled.div`
  display: flex;
  height: 100px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    height: auto;
    flex-direction: column;
    ${props =>
      props.clinicSelected &&
      css`
        flex-direction: row;
        align-items: center;
        position: relative;
      `};
  }
`
const IconBack = styled.i`
  display: none;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    display: block;
    font-size: 34px;
    z-index: 1;
    color: ${props => props.theme.colors.greenThree};
    text-decoration: none;
    position: absolute;
    top: 50%;
    left: -5%;
  }
`
const Tab = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: ${props => props.theme.colors.warmGrey};
  background: ${props => props.theme.colors.white};
  position: relative;

  ${props =>
    props.active &&
    css`
      color: ${props.theme.colors.green};
      background: white;
      box-shadow: inset 0 -6px 0 0 ${props => props.theme.colors.green};
      @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
        box-shadow: inset 0 -8px 0 0 ${props => props.theme.colors.green};
      }
    `} &:hover {
    cursor: pointer;
  }
  &:not(:last-child) {
    margin-right: 5px;
  }
  > h4,
  p {
    margin: 0;
  }
  h4 {
    font-size: 20px;
  }
  p {
    font-size: 12px;
  }
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    height: 100px;
    &:not(:first-child) {
      margin-left: 0;
      margin-top: 28px;
    }
    ${props =>
      props.hide &&
      css`
        display: none;
      `};
  }
`
