import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import to from 'await-catch'
import { connect } from 'react-redux'

import AppointmentHeader from './AppointmentHeader'
import AppointmentFlowTabs from './AppointmentFlowTabs'
import { getSpecialties, getProfessionals, confirmAppointment } from '../api'
import FormSelectProfessional from './Forms/FormSelectProfessional'
import { setAppointmentDetails, setAppointmentStage } from '../actions'
import { APPOINTMENT_STAGE_NEW, CLINICS_ID } from '../constants'
import AppointmentSchedule from './AppointmentSchedule'
import FormConfirmAppointment from './Forms/FormConfirmAppointment'


class NewAppointment extends React.Component {
  state = {
    activeTab: 1,
    specialties: [],
    professionals: [],
    specialty: '',
    professionalSelected: '',
    professional: '',
    schedule: '',
    email: '',
    phone: '',
    clinicChange: 1,
    appointmentId: 0
  }

  async componentDidMount() {
    this.props.setAppointmentStage(APPOINTMENT_STAGE_NEW)

    await this.loadSpecialties();
  }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.appointment.activeClinic.codigoEmpresa !==
      this.props.appointment.activeClinic.codigoEmpresa
    ) {
      this.loadSpecialties();
      this.handleCalendarGoBack();
      this.setState({ clinicChange: this.state.clinicChange + 1 })
    }
  }

  async loadSpecialties() {
    const isSaintRita = this.props.appointment.activeClinic.id === CLINICS_ID.CentroRita

    this.setState({ specialties: [] })
    const request = {
      clinica: this.props.appointment.activeClinic.codigoEmpresa,
      idProfesional: -1,
      idObraSocial: this.props.appointment.patientDetails.idObraSocial,
      idPlan: this.props.appointment.patientDetails.idPlan
    }

    const [err, { data } = {}] = await to(getSpecialties(request))
    if (!err) {
      this.setState({ 
        specialties: data,
       })
    }
  }

  handleSpecialtyChange = async specialtyId => {
    this.setState({ professionals: [] })
    const request = {
      clinica: this.props.appointment.activeClinic.codigoEmpresa,
      idEspecialidad: specialtyId,
      idObraSocial: this.props.appointment.patientDetails.idObraSocial,
      idPlan: this.props.appointment.patientDetails.idPlan
    }

    const [err, { data } = {}] = await to(getProfessionals(request))
    if (!err) {
      this.setState({ professionals: data })
    }
  }

  handleCancel = () => this.props.history.goBack()

  handleProfessionalSubmit = values =>
    this.setState({
      specialty: this.state.specialties.find(s => s.idEspecialidad === Number(values.specialty)),
      professionalSelected: this.state.professionals.find(
        p => p.idProfesional === Number(values.professional)
      ),
      activeTab: 2
    })

  handleScheduleSubmit = ({ schedule: appointment }) => {
    this.setState({
      schedule: appointment.fechaTurnoDateTime,
      professional: this.state.professionals.find(
        p => p.idProfesional === appointment.idProfesional
      ),
      activeTab: 3,
      appointmentId: appointment.idTurno
    })
  }

  handleCalendarGoBack = () =>
    this.setState({
      specialty: '',
      professional: '',
      professionalSelected: '',
      professionals: [],
      activeTab: 1
    })

  handleConfirmGoBack = () => this.setState({ schedule: '', email: '', phone: '', activeTab: 2 })

  handleConfirmSubmit = async values => {
    this.setState({ ...values })
    const params = {
      clinica: this.props.appointment.activeClinic.codigoEmpresa,
      nombreClinica: this.props.appointment.activeClinic.nombre,
      direccionClinica: this.props.appointment.activeClinic.direccion,
      idEspecialidad: this.state.specialty.idEspecialidad,
      nombreEspecialidad: this.state.specialty.nombre,
      idProfesional: this.state.professional.idProfesional,
      nombreProfesional: this.state.professional.nombreProfesional,
      idObraSocial: this.props.appointment.patientDetails.idObraSocial,
      coberturaMedica: this.props.appointment.patientDetails.coberturaMedica,
      plan: this.props.appointment.patientDetails.planLineaCartilla,
      idPlan: this.props.appointment.patientDetails.idPlan,
      idPaciente: this.props.appointment.patientDetails.idPaciente,
      idSistema: '',
      fechaHora: this.state.schedule,
      nroCobertura: this.props.appointment.patientDetails.nroCobertura,
      nroAfiliado: this.props.appointment.patientDetails.nroAfiliado,
      nombreCompletoPaciente: this.props.appointment.patientDetails.nombreApellido,
      nombrePaciente: this.props.appointment.patientDetails.nombres,
      nroDocumento: this.props.appointment.patientDetails.numeroDocumento,
      telefono: this.state.phone,
      mail: this.state.email,
      idTurno: this.state.appointmentId
    }
    const [err, { data } = {}] = await to(confirmAppointment(params))
    if (!err) {
      this.props.setAppointmentDetails({
        specialty: this.state.specialty,
        professional: this.state.professional,
        phone: this.state.phone,
        email: this.state.email,
        schedule: this.state.schedule,
        id: data
      })
      this.props.history.push('/inicio/confirmacion')
    }
  }

  render() {

    return (
      <Content>
        <AppointmentHeader
          sectionTitle="Reservar Turno"
          fullName={this.props.appointment.patientDetails.nombreApellido}
          healthInsurance={this.props.appointment.patientDetails.coberturaMedica}
          memberNumber={this.props.appointment.patientDetails.nroAfiliado}
        />
        <AppointmentFlowTabs
          listTabs={[
            { id: 1, name: 'Elegí especialidad y médico' },
            { id: 2, name: 'Elegí día y hora' },
            { id: 3, name: 'Confirmá' }
          ]}
          activeTab={this.state.activeTab}
        />
        {this.state.activeTab === 1 && (
          <Fragment>
            <FormSelectProfessional
              key={this.state.clinicChange}
              specialties={this.state.specialties}
              professionals={this.state.professionals}
              onSpecialtyChange={this.handleSpecialtyChange}
              onCancel={this.handleCancel}
              onSubmit={this.handleProfessionalSubmit}
            />
          </Fragment>
        )}
        {this.state.activeTab === 2 && (
          <AppointmentSchedule
            specialty={this.state.specialty.idEspecialidad}
            professional={this.state.professionalSelected.idProfesional}
            onSubmit={this.handleScheduleSubmit}
            onCancel={this.handleCalendarGoBack}
          />
        )}
        {this.state.activeTab === 3 && (
          <FormConfirmAppointment
            onCancel={this.handleConfirmGoBack}
            onSubmit={this.handleConfirmSubmit}
            specialty={this.state.specialty}
            professional={this.state.professional}
            clinic={this.props.appointment.activeClinic}
            patient={this.props.appointment.patientDetails}
            schedule={this.state.schedule}
          />
        )}
      </Content>
    )
  }
}

const mapDispatchToProps = { setAppointmentDetails, setAppointmentStage }
function mapStateToProps({ appointment }) {
  return { appointment }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewAppointment)

/*
 * Styles
 */
const margin = css`
  margin: 5px 0;
`
const Content = styled.div`
  ${margin} padding: 20px 40px 40px;
  background: ${props => props.theme.colors.cloudyBlueThree};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    padding: 10px 20px 20px;
  }
`
