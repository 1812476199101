import { createGlobalStyle } from 'styled-components'

const fontFamily = 'Source Sans Pro, sans-serif'

export const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

    html {
    box-sizing: border-box;
    }

    *, *:before, *:after {
    box-sizing: inherit;
    }

    body {
    margin: 0;
    font-family: ${fontFamily};
    }

    input, textarea, select, button {
    font-family: ${fontFamily};
    }

    button,
    a {
    &:hover {
        cursor: pointer;
    }
    }
`
