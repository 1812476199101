import React from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'
import { Formik } from 'formik'
import * as yup from 'yup'
import PropTypes from 'prop-types'

import { Button } from '../../styles/generics'
import { SelectField } from '../../styles/forms'

const SCHEDULE_EMPTY = -1
const SCHEDULE_FIRST = 0

const validations = yup.object().shape({
  schedule: yup.number().required('Campo requerido')
})

const capitalizeFLetter = (text) => { 
  return text.toLowerCase().split(' ').map(word => 
   word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
} 

const optionLabel = (allDoctors, schedule, name) =>
  `${moment(schedule).format('HH:mm')}${allDoctors ? ` - ${capitalizeFLetter(name)}` : ''}`

const FormSelectAppointment = props => (
  <Container>
    <Formik
      onSubmit={props.onSubmit}
      initialValues={
        !props.schedules.length ? { schedule: SCHEDULE_EMPTY } : { schedule: SCHEDULE_FIRST }
      }
      validationSchema={validations}
      enableReinitialize={true}
      render={renderProps => (
        <Form onSubmit={renderProps.handleSubmit}>
          <FormInputs notShow={props.schedules.length === 0}>
            <Label>Horarios disponibles</Label>
            <SelectField
              name="schedule"
              id="schedule"
              options={props.schedules.map((s, index) => ({
                id: index,
                name: optionLabel(props.allDoctors, s.fechaTurnoDateTime, s.nombreProfesional)
              }))}
              warning={renderProps.errors.schedule && renderProps.touched.schedule}
              white
            />
          </FormInputs>
          <ButtonsContainer>
            <SecondButton onClick={props.onCancel}>Volver</SecondButton>
            <Button type="submit" disabled={props.schedules.length === 0}>
              Confirmar
            </Button>
          </ButtonsContainer>
        </Form>
      )}
    />
  </Container>
)

FormSelectAppointment.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  schedules: PropTypes.arrayOf(
    PropTypes.shape({
      fechaTurno: PropTypes.string,
      fechaTurnoDateTime: PropTypes.string,
      idEspecialidad: PropTypes.number,
      idProfesional: PropTypes.number,
      nombreEspecialidad: PropTypes.string,
      nombreProfesional: PropTypes.string
    })
  ).isRequired
}

export default FormSelectAppointment

/**
 * Styles
 */
const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    margin-top: 0;
  }
`
const Form = styled.form`
  width: 100%;
  padding: 0 20px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    padding: 0;
  }
`
const Label = styled.span`
  display: block;
  text-align: center;
  width: 100%;
  color: ${props => props.theme.colors.green};
  font-weight: 600;
  margin-bottom: 6.5px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    font-size: 13px;
  }
`
const FormInputs = styled.div`
  width: 520px;
  margin: auto;
  margin-bottom: 60px;
  ${props =>
    props.notShow &&
    css`
      visibility: hidden;
      @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
        display: none;
      }
    `};
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 100%;
    margin: 20px 0 0 0;
  }
`
const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 39px;
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    grid-template-columns: 1fr;
    grid-gap: 12px;
    margin-top: 30px;
  }
`
const SecondButton = styled(Button)`
  @media (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    grid-row: 2;
  }
`
