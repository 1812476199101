import {
  LOGIN,
  SHOW_LOADER,
  HIDE_LOADER,
  SHOW_ERROR_MESSAGE,
  HIDE_ERROR_MESSAGE,
  LOGOUT,
  SET_ACTIVE_CLINIC,
  SET_PATIENT_DETAILS,
  SET_APPOINTMENT_DETAILS,
  SET_APPOINTMENT_STAGE,
  CLEAR_APPOINTMENT_STATE
} from './actions'

/*
 * Auth
 */
export const authReducer = function(state = {}, action) {
  switch (action.type) {
    case LOGIN:
      return action.payload
    case LOGOUT:
      return {}
    default:
      return state
  }
}

/*
 * Loader
 */
export const loaderReducer = function(state = false, action) {
  switch (action.type) {
    case SHOW_LOADER:
      return true
    case HIDE_LOADER:
      return false
    default:
      return state
  }
}

/*
 * Error Message
 */
const errorMessageDefault = {
  message: ''
}
export const errorMessageReducer = function(state = errorMessageDefault, action) {
  switch (action.type) {
    case SHOW_ERROR_MESSAGE:
      return { message: action.payload }
    case HIDE_ERROR_MESSAGE:
      return errorMessageDefault
    default:
      return state
  }
}

/*
 * Appointment
 */
export const appointmentDefaults = {
  activeClinic: null,
  patientDetails: null,
  appointmentDetails: null,
  appointmentStage: 0
}

export const appointmentReducer = function(state = appointmentDefaults, action) {
  switch (action.type) {
    case SET_ACTIVE_CLINIC:
      return {
        ...state,
        activeClinic: action.payload
      }
    case SET_PATIENT_DETAILS:
      return {
        ...state,
        patientDetails: action.payload
      }
    case SET_APPOINTMENT_DETAILS:
      return {
        ...state,
        appointmentDetails: action.payload
      }
    case CLEAR_APPOINTMENT_STATE:
      return {
        ...appointmentDefaults,
        patientDetails: state.patientDetails
      }
    case SET_APPOINTMENT_STAGE:
      return {
        ...state,
        appointmentStage: action.payload
      }
    default:
      return state
  }
}
