import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import withAuth from '../hoc/withAuth'


const PrivateRoute = ({ auth, component: Component, ...rest }) => {
  if (!Object.keys(auth).length) {
    return <Redirect to="/" />
  }

  return <Route {...rest} render={props => <Component {...props} />} />
}

export default withAuth(PrivateRoute)